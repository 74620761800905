<script lang="ts">
    import { fade } from 'svelte/transition';
    import { createEventDispatcher } from 'svelte';
    import { browser } from '$app/env';

    export let text: string = `Diese Webseite nutzt Einbettungen und damit Cookies externer Anbieter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Mehr Infos finden Sie in unserer`;
    export let acceptText: string = 'Cookies zulassen';
    export let rejectText: string = 'Cookies nicht erlauben';

    let show = true;

    type customEvents = { allowcookies: undefined; rejectcookies: undefined };
    const dispatch = createEventDispatcher<customEvents>();

    function close() {
        show = false;
    }
</script>

{#if show && browser}
    <section transition:fade class="h3">
        <div class="btn-group">
            <button
                on:click={() => {
                    dispatch('allowcookies');
                    close();
                }}
                class="link-button fix-font-shift"
                data-text={acceptText}>{acceptText}</button
            >
            <button
                on:click={() => {
                    dispatch('rejectcookies');
                    close();
                }}
                class="link-button fix-font-shift"
                data-text={rejectText}>{rejectText}</button
            >
        </div>
        <div class="cookie-text">
            {`${text} `}<a href="/datenschutzerklaerung">Datenschutzerklärung</a>
        </div>
    </section>
{/if}

<style lang="scss">
    section {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: var(--s20);

        display: flex;
        flex-direction: column;
        background: white;
        z-index: var(--z-cookie-banner);

        .cookie-text {
            max-width: 70ch;
            margin-right: var(--s30);
        }
        .btn-group {
            display: flex;
            flex-direction: row;
            min-height: 100%;
            margin-bottom: calc(var(--s20) - 5px);
            button + button {
                margin-left: calc(var(--s20));
            }
            button {
                text-align: left;
                &::before {
                    display: none;
                }
            }
        }
        a {
            text-decoration: underline;
        }
    }
</style>
