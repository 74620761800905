<script>
    // TODO Remove Sentry befor production.
    import { VITE_SENTRY_RELEASE_ID } from '$lib/envs';
    // import * as Sentry from '@sentry/browser';
    // import { Integrations } from '@sentry/tracing';

    // Sentry.init({
    //     dsn: 'https://7a68472eac4a47aa802f95cee1cb66de@sentry.neesh.dev/4',
    //     integrations: [new Integrations.BrowserTracing()],
    //     // @ts-ignore
    //     release: VITE_SENTRY_RELEASE_ID,

    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 0.5,
    // });

    import '../app.scss';
    import 'focus-visible/dist/focus-visible.min.js';
    import { onMount, tick } from 'svelte';
    import LoadingIndictator from '$lib/LoadingIndictator.svelte';
    import { navigating } from '$app/stores';
    import CookieBanner from '$lib/cookieFunc/CookieBanner.svelte';
    import { localStore } from '$lib/api/localStorage';

    let testID = 'loading'; // Set to "loaded" to indicate cypress testrunner that testing can start now.
    onMount(() => {
        tick().then(() => {
            testID = 'loaded';
        });
    });
</script>

<svelte:head>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
    <!-- Primary Meta Tags -->
    <title>WohnWissen Übersetzen</title>
    <meta name="title" content=" WohnWissen Übersetzen" />
    <meta
        name="description"
        content="Die Lern- und Lehrplattform “WohnWissen Übersetzen” beschäftigt sich mit beispielhaften Formen und Praktiken kollektiven Bauens und Wohnens."
    />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://wohnwissen.net/" />
    <meta property="og:title" content=" WohnWissen Übersetzen" />
    <meta
        property="og:description"
        content="Die Lern- und Lehrplattform “WohnWissen Übersetzen” beschäftigt sich mit beispielhaften Formen und Praktiken kollektiven Bauens und Wohnens."
    />
    <meta property="og:image" content="https://wohnwissen.net/wohn_wissen_preview.png" />

    <!-- Twitter -->
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://wohnwissen.net/" />
    <meta property="twitter:title" content=" WohnWissen Übersetzen" />
    <meta
        property="twitter:description"
        content="Die Lern- und Lehrplattform “WohnWissen Übersetzen” beschäftigt sich mit beispielhaften Formen und Praktiken kollektiven Bauens und Wohnens."
    />
    <meta property="twitter:image" content="https://wohnwissen.net/wohn_wissen_preview.png" />
</svelte:head>

<slot />

<!-- Element nesseary for cypress test runner to wait until svelte comp are loaded. -->
<div data-cy={testID} />
{#if $navigating}
    <LoadingIndictator color="black" />
{/if}

<!-- Cookie Banner -->
{#if $localStore.showCookieBanner}
    <CookieBanner
        on:allowcookies={() => {
            $localStore.showCookieBanner = false;
            $localStore.cookiesAllowed = true;
        }}
        on:rejectcookies={() => {
            $localStore.showCookieBanner = false;
            $localStore.cookiesAllowed = false;
        }}
    />
{/if}

<!-- Debug Helper -->

<!-- <p style="position: fixed; top: 0px;">
  Cookies allowed: {$localStore.cookiesAllowed}
  <button on:click={() => ($localStore.showCookieBanner = true)}>Reset</button>
</p> -->
<style lang="scss">
    div[data-cy] {
        position: fixed;
        top: -9999px;
        left: -9999px;
    }
</style>
